import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const debug = false;
const version = "1.0.2";

const AiHelper = () => {
  const [dropdownValue, setDropdownValue] = useState(2);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [apiResponse, setApiResponse] = useState({
    answer: "",
    answerIsFromCache: false,
  });
  const [useCaseNames, setUseCaseNames] = useState([]);
  const [apiVersion, setVersion] = useState([]);
  
  const baseUrl = window.location.hostname === 'localhost' 
    ? "http://localhost:32771/AiServices"
    : "https://aiservice.expressit.se/AiServices";

  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to minimum to allow shrinking
      textarea.style.height = '3em';
      const scrollHeight = textarea.scrollHeight;
      const maxHeight = parseFloat(getComputedStyle(textarea).lineHeight) * 7;
      textarea.style.height = Math.min(scrollHeight, maxHeight) + 'px';
    }
  };

  useEffect(() => {
    const fetchUseCaseNames = async () => {
      try {
        const response = await axios.get(`${baseUrl}/GetUseCases`);
        setUseCaseNames(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchVersion = async () => {
      try {
        const response = await axios.get(`${baseUrl}/GetVersion`);
        const version = response.data;
        // Store the version in a separate variable
        setVersion(version);
      } catch (error) {
        console.error(error);
      }
    };

    fetchVersion();
    fetchUseCaseNames();
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, []);

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
    setTextEdited(true);
  };

  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
    setTextEdited(true);
    if (event.target.value.length >= 5000) {
      alert("No more than 5000 characters allowed.");
    }
    adjustTextareaHeight();
  };

  const [isLoading, setIsLoading] = useState(false);
  const [textEdited, setTextEdited] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setTextEdited(false);
      setApiResponse({
        answer: "",
        answerIsFromCache: false,
      });
      const userQuestion = {
        UseCaseId: dropdownValue,
        Question: textAreaValue,
      };

      const response = await axios.post(`${baseUrl}/GetAnswer`, userQuestion);
      setApiResponse(response.data.value);
      if (debug) {
        console.log(apiResponse);
      }
    } catch (error) {
      console.error(error);
      alert(
        "Something went wrong when trying to get the answer from the server."
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Add a spinner when isLoading is true
  const renderSpinner = () => {
    return isLoading ? <div className="spinner"></div> : null;
  };

  const handleClear = () => {
    setTextAreaValue("");
    setApiResponse({
      answer: "",
      answerIsFromCache: false,
    });
    // Reset textarea height when clearing
    setTimeout(adjustTextareaHeight, 0);
  };

  const handleCopyToClipboard = () => {
    let textToCopy = apiResponse.answer;
    
    // Split the text into lines
    const lines = textToCopy.split('\n');
    
    // Remove first line if it only contains ```
    if (lines.length > 0 && lines[0].trim().match(/^```\w*$/)) {
      lines.shift();
    }
    
    // Remove last line if it only contains ```
    if (lines.length > 0 && lines[lines.length - 1].trim() === '```') {
      lines.pop();
    }
    
    // Join the lines back together
    textToCopy = lines.join('\n');
    
    // Copy the modified text
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <div className="container">
      <textarea
        ref={textareaRef}
        maxLength={5000}
        value={textAreaValue}
        onChange={handleTextAreaChange}
      />

      <div className="button-container">
        <button className="small-button, clear-button" onClick={handleClear}>
          Clear
        </button>
        <select value={dropdownValue} onChange={handleDropdownChange}>
        {useCaseNames.map((useCase) => (
          <option key={useCase.id} value={useCase.id}>
            {useCase.name}
          </option>
        ))}
      </select>
        <button
          className="small-button, submit-button"
          onClick={handleSubmit}
          disabled={!textEdited || textAreaValue.length < 1}
        >
          Submit
        </button>
      </div>
      <div className="status" style={{ display: isLoading ? "block" : "none" }}>
        Waiting for AI response...
      </div>
      <div className="button-container">
        <button
          className="small-button, copy-button"
          onClick={handleCopyToClipboard}
          disabled={!apiResponse.answer}
        >
          Copy answer
        </button>
      </div>
      <div className="answer" style={{ whiteSpace: "pre-wrap" }}>
        <p>{apiResponse.answer}</p>
      </div>
      <footer className="footer">
        <p>
          Version: {version} (UX) / {apiVersion} (API) {" "}
          {apiResponse.answerIsFromCache ? "(cached)" : "(generated)"}
        </p>
        <p>
          Contact:{" "}
          <a href="https://linktr.ee/lucius418">https://linktr.ee/lucius418</a>
        </p>
      </footer>
    </div>
  );
};

export default AiHelper;
